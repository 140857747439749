import React from "react";
import { styled } from '@mui/material/styles';
import _, { before } from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";
import "./niki.css";

import "core-js/proposals/string-match-all";//polyfill for old Androids; TODO - move away from here


const Root = styled('div')(({ theme }) => ({
    height: "100vh",
    textTransform:"uppercase",
    background: '#fc64a9',
}));
Root.defaultProps = {
    "data-id": "Root"
  };

const BackgroundImage = styled(BgImage)(({ theme }) => ({
    flexGrow: 0,
    backgroundSize: "cover",
    width:"100%",
    height: '100vh',
    position: 'relative',

}));
BackgroundImage.defaultProps = {
    "data-id": "BackgroundImage"
  };

const Logo = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 10,
    left: 16,
    background: 'url(/images/logo-kinemathek.png) no-repeat',
    height: 120,
    color: 'transparent',
    display: 'none',
}));
Logo.defaultProps = {
    "data-id": "Logo"
  };

const Claim = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 181,
    left: 16,
    height: 60,
    background: 'url(/images/audioguide.png) no-repeat',
    color: 'transparent',
    // '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     bottom: 0,
    //     left: 50,
    //     width: '100%',
    //     height: '1rem',
    //     display: 'block',
    //     background:'hotpink'
    // }
}));

const Children = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '17%',
    left: 'calc(50% - 65px)',
    width: 130,
    height: 130,
    background: '#25f0d2',
    borderRadius: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ChildLink = styled(Link)(({ theme }) => ({
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: '#000',
    textDecoration: 'none',
    textAlign: 'center',
    lineHeight: 1.1,
}));

const ChildDiv = styled('div')(({ theme }) => ({
}));

const EntityPage = (props) => {

    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;

    const image = getImage(entity.largeImage?.localFile);
    const { defaultLocale } = pageContext;

    return <Root>
        <Wrapper className="wrapper" {...props} topbar={false} title={entity.title} transparentAppbar={true}>
            <div className="wrapper2" style={{ overflowX: 'hidden' }/*TODO - var*/}>

                <BackgroundImage className="background"  image={image}>
                <img src="/images/nana-claim.png" className="claim"></img>
                <img src="/images/nana.png" className="nana"></img>
                <img src="/images/schirn-logo.png" className="logo"></img>
                    <Children className="linkliste" >
                        {children.map((child, n) => {
                            const url = getEntityUrl({ entity: child, defaultLocale });

                            return <ChildLink className="childlink___" to={url}>{child.title}</ChildLink>
                        })}
                    </Children>
                </BackgroundImage>

            </div>
        </Wrapper>
    </Root>
}

export default EntityPage;